/* VARS */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:500");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffcc00;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ffcc00;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffcc00;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #3eb5e5;
  text-decoration: underline;
  background-color: transparent; }
  a:hover {
    color: #198ebd;
    text-decoration: none; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fff1b8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffe47a; }

.table-hover .table-primary:hover {
  background-color: #ffec9f; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffec9f; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff1b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe47a; }

.table-hover .table-warning:hover {
  background-color: #ffec9f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffec9f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #7a7a7a;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #231f20;
    background-color: #fff;
    border-color: #7a7a7a;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #231f20; }

select.form-control:focus::-ms-value {
  color: #231f20;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5000px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 1; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #ffcc00;
  border-color: #ffcc00; }
  .btn-primary:hover {
    color: #212529;
    background-color: #d9ad00;
    border-color: #cca300; }
  .btn-primary:focus, .btn-primary.focus {
    color: #212529;
    background-color: #d9ad00;
    border-color: #cca300;
    box-shadow: 0 0 0 0 rgba(222, 179, 6, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #ffcc00;
    border-color: #ffcc00; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #cca300;
    border-color: #bf9900; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(222, 179, 6, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffcc00;
  border-color: #ffcc00; }
  .btn-warning:hover {
    color: #212529;
    background-color: #d9ad00;
    border-color: #cca300; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #d9ad00;
    border-color: #cca300;
    box-shadow: 0 0 0 0 rgba(222, 179, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffcc00;
    border-color: #ffcc00; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #cca300;
    border-color: #bf9900; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(222, 179, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0 rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #ffcc00;
  border-color: #ffcc00; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #ffcc00;
    border-color: #ffcc00; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ffcc00;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #ffcc00;
    border-color: #ffcc00; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffcc00;
  border-color: #ffcc00; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffcc00;
    border-color: #ffcc00; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffcc00;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffcc00;
    border-color: #ffcc00; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #3eb5e5;
  text-decoration: underline; }
  .btn-link:hover {
    color: #198ebd;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 5000px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5000px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ffcc00; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #7a7a7a;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ffcc00;
    background-color: #ffcc00; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #7a7a7a; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fff0b3;
    border-color: #fff0b3; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ffcc00;
  background-color: #ffcc00; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 204, 0, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(255, 204, 0, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 204, 0, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(255, 204, 0, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #7a7a7a;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #7a7a7a;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.25); }
    .custom-select:focus::-ms-value {
      color: #231f20;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #231f20; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #7a7a7a;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  background-color: #fff;
  border: 1px solid #7a7a7a;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #231f20;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #ffcc00;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fff0b3; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ffcc00;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #fff0b3; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #ffcc00;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #fff0b3; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ffcc00; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5000px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0 0 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0 0; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3eb5e5;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #198ebd;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ffcc00;
  border-color: #ffcc00; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #ffcc00; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #cca300; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffcc00; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cca300; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 204, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 600; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #856a00;
  background-color: #fff5cc;
  border-color: #fff1b8; }
  .alert-primary hr {
    border-top-color: #ffec9f; }
  .alert-primary .alert-link {
    color: #524100; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856a00;
  background-color: #fff5cc;
  border-color: #fff1b8; }
  .alert-warning hr {
    border-top-color: #ffec9f; }
  .alert-warning .alert-link {
    color: #524100; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ffcc00;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ffcc00;
    border-color: #ffcc00; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #856a00;
  background-color: #fff1b8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #856a00;
    background-color: #ffec9f; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #856a00;
    border-color: #856a00; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856a00;
  background-color: #fff1b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856a00;
    background-color: #ffec9f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856a00;
    border-color: #856a00; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ffcc00 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #cca300 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffcc00 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cca300 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ffcc00 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffcc00 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #ffcc00 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #b38f00 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffcc00 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b38f00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* General CSS */
@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/roboto-thin.woff2") format("woff2"), url("../assets/fonts/roboto-thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/roboto-light.woff2") format("woff2"), url("../assets/fonts/roboto-light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/roboto-regular.woff2") format("woff2"), url("../assets/fonts/roboto-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/roboto-medium.woff2") format("woff2"), url("../assets/fonts/roboto-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/roboto-bold.woff2") format("woff2"), url("../assets/fonts/roboto-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

body {
  color: #231f20;
  background: #fff;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 14px;
  line-height: 1.71429; }
  @media (min-width: 768px) {
    body {
      font-size: 16px;
      line-height: 1.5; } }
  body * {
    font-family: 'Roboto' !important; }

.tooltip {
  font-family: 'Roboto' !important; }
  .tooltip * {
    font-family: 'Roboto' !important; }

.rc-anchor-normal * {
  font-family: 'Roboto' !important; }

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none; }

a.disabled {
  color: #d1d1d1;
  opacity: 1; }

p.heading-sub {
  margin-bottom: 47px;
  margin-top: -20px; }

/* Error cases */
.with-errors {
  display: none;
  font-size: 14px;
  color: #ff001f;
  padding: 8px 0;
  clear: both; }

.with-guilde {
  color: #95959D;
  padding: 8px 0;
  clear: both; }

.has-error input.form-control {
  border-color: #ff001f !important;
  border-width: 2px !important; }

.has-error .with-errors {
  display: block; }

/*----------------Default button-----------------*/
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    transform: translate3d(4px, 0, 0); } }

.no-padding {
  padding: 0; }

.debug-border {
  border: 1px solid black; }

.display-table {
  display: table; }

.no-right-border {
  border-right: 0 !important; }

.no-bottom-border {
  border-bottom: 0 !important; }

.vertical-centered {
  display: table-cell;
  vertical-align: middle; }

.ib-page-header {
  font-size: 40px;
  display: block; }

.error-text {
  color: #ff001f; }

.dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #7a7a7a; }

.form-control {
  background-clip: padding-box;
  box-shadow: none; }

.container {
  max-width: 1140px; }

.font-size-1 {
  font-size: 1px; }

@media (min-width: 768px) {
  .font-size-md-1 {
    font-size: 1px; } }

.font-size-2 {
  font-size: 2px; }

@media (min-width: 768px) {
  .font-size-md-2 {
    font-size: 2px; } }

.font-size-3 {
  font-size: 3px; }

@media (min-width: 768px) {
  .font-size-md-3 {
    font-size: 3px; } }

.font-size-4 {
  font-size: 4px; }

@media (min-width: 768px) {
  .font-size-md-4 {
    font-size: 4px; } }

.font-size-5 {
  font-size: 5px; }

@media (min-width: 768px) {
  .font-size-md-5 {
    font-size: 5px; } }

.font-size-6 {
  font-size: 6px; }

@media (min-width: 768px) {
  .font-size-md-6 {
    font-size: 6px; } }

.font-size-7 {
  font-size: 7px; }

@media (min-width: 768px) {
  .font-size-md-7 {
    font-size: 7px; } }

.font-size-8 {
  font-size: 8px; }

@media (min-width: 768px) {
  .font-size-md-8 {
    font-size: 8px; } }

.font-size-9 {
  font-size: 9px; }

@media (min-width: 768px) {
  .font-size-md-9 {
    font-size: 9px; } }

.font-size-10 {
  font-size: 10px; }

@media (min-width: 768px) {
  .font-size-md-10 {
    font-size: 10px; } }

.font-size-11 {
  font-size: 11px; }

@media (min-width: 768px) {
  .font-size-md-11 {
    font-size: 11px; } }

.font-size-12 {
  font-size: 12px; }

@media (min-width: 768px) {
  .font-size-md-12 {
    font-size: 12px; } }

.font-size-13 {
  font-size: 13px; }

@media (min-width: 768px) {
  .font-size-md-13 {
    font-size: 13px; } }

.font-size-14 {
  font-size: 14px; }

@media (min-width: 768px) {
  .font-size-md-14 {
    font-size: 14px; } }

.font-size-15 {
  font-size: 15px; }

@media (min-width: 768px) {
  .font-size-md-15 {
    font-size: 15px; } }

.font-size-16 {
  font-size: 16px; }

@media (min-width: 768px) {
  .font-size-md-16 {
    font-size: 16px; } }

.font-size-17 {
  font-size: 17px; }

@media (min-width: 768px) {
  .font-size-md-17 {
    font-size: 17px; } }

.font-size-18 {
  font-size: 18px; }

@media (min-width: 768px) {
  .font-size-md-18 {
    font-size: 18px; } }

.font-size-19 {
  font-size: 19px; }

@media (min-width: 768px) {
  .font-size-md-19 {
    font-size: 19px; } }

.font-size-20 {
  font-size: 20px; }

@media (min-width: 768px) {
  .font-size-md-20 {
    font-size: 20px; } }

.font-size-21 {
  font-size: 21px; }

@media (min-width: 768px) {
  .font-size-md-21 {
    font-size: 21px; } }

.font-size-22 {
  font-size: 22px; }

@media (min-width: 768px) {
  .font-size-md-22 {
    font-size: 22px; } }

.font-size-23 {
  font-size: 23px; }

@media (min-width: 768px) {
  .font-size-md-23 {
    font-size: 23px; } }

.font-size-24 {
  font-size: 24px; }

@media (min-width: 768px) {
  .font-size-md-24 {
    font-size: 24px; } }

.font-size-25 {
  font-size: 25px; }

@media (min-width: 768px) {
  .font-size-md-25 {
    font-size: 25px; } }

.font-size-26 {
  font-size: 26px; }

@media (min-width: 768px) {
  .font-size-md-26 {
    font-size: 26px; } }

.font-size-27 {
  font-size: 27px; }

@media (min-width: 768px) {
  .font-size-md-27 {
    font-size: 27px; } }

.font-size-28 {
  font-size: 28px; }

@media (min-width: 768px) {
  .font-size-md-28 {
    font-size: 28px; } }

.font-size-29 {
  font-size: 29px; }

@media (min-width: 768px) {
  .font-size-md-29 {
    font-size: 29px; } }

.font-size-30 {
  font-size: 30px; }

@media (min-width: 768px) {
  .font-size-md-30 {
    font-size: 30px; } }

.font-size-31 {
  font-size: 31px; }

@media (min-width: 768px) {
  .font-size-md-31 {
    font-size: 31px; } }

.font-size-32 {
  font-size: 32px; }

@media (min-width: 768px) {
  .font-size-md-32 {
    font-size: 32px; } }

.font-size-33 {
  font-size: 33px; }

@media (min-width: 768px) {
  .font-size-md-33 {
    font-size: 33px; } }

.font-size-34 {
  font-size: 34px; }

@media (min-width: 768px) {
  .font-size-md-34 {
    font-size: 34px; } }

.font-size-35 {
  font-size: 35px; }

@media (min-width: 768px) {
  .font-size-md-35 {
    font-size: 35px; } }

.font-size-36 {
  font-size: 36px; }

@media (min-width: 768px) {
  .font-size-md-36 {
    font-size: 36px; } }

.font-size-37 {
  font-size: 37px; }

@media (min-width: 768px) {
  .font-size-md-37 {
    font-size: 37px; } }

.font-size-38 {
  font-size: 38px; }

@media (min-width: 768px) {
  .font-size-md-38 {
    font-size: 38px; } }

.font-size-39 {
  font-size: 39px; }

@media (min-width: 768px) {
  .font-size-md-39 {
    font-size: 39px; } }

.font-size-40 {
  font-size: 40px; }

@media (min-width: 768px) {
  .font-size-md-40 {
    font-size: 40px; } }

.font-size-41 {
  font-size: 41px; }

@media (min-width: 768px) {
  .font-size-md-41 {
    font-size: 41px; } }

.font-size-42 {
  font-size: 42px; }

@media (min-width: 768px) {
  .font-size-md-42 {
    font-size: 42px; } }

.font-size-43 {
  font-size: 43px; }

@media (min-width: 768px) {
  .font-size-md-43 {
    font-size: 43px; } }

.font-size-44 {
  font-size: 44px; }

@media (min-width: 768px) {
  .font-size-md-44 {
    font-size: 44px; } }

.font-size-45 {
  font-size: 45px; }

@media (min-width: 768px) {
  .font-size-md-45 {
    font-size: 45px; } }

.font-size-46 {
  font-size: 46px; }

@media (min-width: 768px) {
  .font-size-md-46 {
    font-size: 46px; } }

.font-size-47 {
  font-size: 47px; }

@media (min-width: 768px) {
  .font-size-md-47 {
    font-size: 47px; } }

.font-size-48 {
  font-size: 48px; }

@media (min-width: 768px) {
  .font-size-md-48 {
    font-size: 48px; } }

.font-size-49 {
  font-size: 49px; }

@media (min-width: 768px) {
  .font-size-md-49 {
    font-size: 49px; } }

.font-size-50 {
  font-size: 50px; }

@media (min-width: 768px) {
  .font-size-md-50 {
    font-size: 50px; } }

.font-size-51 {
  font-size: 51px; }

@media (min-width: 768px) {
  .font-size-md-51 {
    font-size: 51px; } }

.font-size-52 {
  font-size: 52px; }

@media (min-width: 768px) {
  .font-size-md-52 {
    font-size: 52px; } }

.font-size-53 {
  font-size: 53px; }

@media (min-width: 768px) {
  .font-size-md-53 {
    font-size: 53px; } }

.font-size-54 {
  font-size: 54px; }

@media (min-width: 768px) {
  .font-size-md-54 {
    font-size: 54px; } }

.font-size-55 {
  font-size: 55px; }

@media (min-width: 768px) {
  .font-size-md-55 {
    font-size: 55px; } }

.font-size-56 {
  font-size: 56px; }

@media (min-width: 768px) {
  .font-size-md-56 {
    font-size: 56px; } }

.font-size-57 {
  font-size: 57px; }

@media (min-width: 768px) {
  .font-size-md-57 {
    font-size: 57px; } }

.font-size-58 {
  font-size: 58px; }

@media (min-width: 768px) {
  .font-size-md-58 {
    font-size: 58px; } }

.font-size-59 {
  font-size: 59px; }

@media (min-width: 768px) {
  .font-size-md-59 {
    font-size: 59px; } }

.font-size-60 {
  font-size: 60px; }

@media (min-width: 768px) {
  .font-size-md-60 {
    font-size: 60px; } }

.font-size-61 {
  font-size: 61px; }

@media (min-width: 768px) {
  .font-size-md-61 {
    font-size: 61px; } }

.font-size-62 {
  font-size: 62px; }

@media (min-width: 768px) {
  .font-size-md-62 {
    font-size: 62px; } }

.font-size-63 {
  font-size: 63px; }

@media (min-width: 768px) {
  .font-size-md-63 {
    font-size: 63px; } }

.font-size-64 {
  font-size: 64px; }

@media (min-width: 768px) {
  .font-size-md-64 {
    font-size: 64px; } }

.font-size-65 {
  font-size: 65px; }

@media (min-width: 768px) {
  .font-size-md-65 {
    font-size: 65px; } }

.font-size-66 {
  font-size: 66px; }

@media (min-width: 768px) {
  .font-size-md-66 {
    font-size: 66px; } }

.font-size-67 {
  font-size: 67px; }

@media (min-width: 768px) {
  .font-size-md-67 {
    font-size: 67px; } }

.font-size-68 {
  font-size: 68px; }

@media (min-width: 768px) {
  .font-size-md-68 {
    font-size: 68px; } }

.font-size-69 {
  font-size: 69px; }

@media (min-width: 768px) {
  .font-size-md-69 {
    font-size: 69px; } }

.font-size-70 {
  font-size: 70px; }

@media (min-width: 768px) {
  .font-size-md-70 {
    font-size: 70px; } }

.font-size-71 {
  font-size: 71px; }

@media (min-width: 768px) {
  .font-size-md-71 {
    font-size: 71px; } }

.font-size-72 {
  font-size: 72px; }

@media (min-width: 768px) {
  .font-size-md-72 {
    font-size: 72px; } }

.font-weight-100 {
  font-weight: 100; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.font-weight-1000 {
  font-weight: 1000; }

.gap-1 {
  gap: 1px; }

@media (min-width: 768px) {
  .gap-md-1 {
    gap: 1px; } }

.gap-2 {
  gap: 2px; }

@media (min-width: 768px) {
  .gap-md-2 {
    gap: 2px; } }

.gap-3 {
  gap: 3px; }

@media (min-width: 768px) {
  .gap-md-3 {
    gap: 3px; } }

.gap-4 {
  gap: 4px; }

@media (min-width: 768px) {
  .gap-md-4 {
    gap: 4px; } }

.gap-5 {
  gap: 5px; }

@media (min-width: 768px) {
  .gap-md-5 {
    gap: 5px; } }

.gap-6 {
  gap: 6px; }

@media (min-width: 768px) {
  .gap-md-6 {
    gap: 6px; } }

.gap-7 {
  gap: 7px; }

@media (min-width: 768px) {
  .gap-md-7 {
    gap: 7px; } }

.gap-8 {
  gap: 8px; }

@media (min-width: 768px) {
  .gap-md-8 {
    gap: 8px; } }

.gap-9 {
  gap: 9px; }

@media (min-width: 768px) {
  .gap-md-9 {
    gap: 9px; } }

.gap-10 {
  gap: 10px; }

@media (min-width: 768px) {
  .gap-md-10 {
    gap: 10px; } }

.gap-11 {
  gap: 11px; }

@media (min-width: 768px) {
  .gap-md-11 {
    gap: 11px; } }

.gap-12 {
  gap: 12px; }

@media (min-width: 768px) {
  .gap-md-12 {
    gap: 12px; } }

.gap-13 {
  gap: 13px; }

@media (min-width: 768px) {
  .gap-md-13 {
    gap: 13px; } }

.gap-14 {
  gap: 14px; }

@media (min-width: 768px) {
  .gap-md-14 {
    gap: 14px; } }

.gap-15 {
  gap: 15px; }

@media (min-width: 768px) {
  .gap-md-15 {
    gap: 15px; } }

.gap-16 {
  gap: 16px; }

@media (min-width: 768px) {
  .gap-md-16 {
    gap: 16px; } }

.gap-17 {
  gap: 17px; }

@media (min-width: 768px) {
  .gap-md-17 {
    gap: 17px; } }

.gap-18 {
  gap: 18px; }

@media (min-width: 768px) {
  .gap-md-18 {
    gap: 18px; } }

.gap-19 {
  gap: 19px; }

@media (min-width: 768px) {
  .gap-md-19 {
    gap: 19px; } }

.gap-20 {
  gap: 20px; }

@media (min-width: 768px) {
  .gap-md-20 {
    gap: 20px; } }

.gap-21 {
  gap: 21px; }

@media (min-width: 768px) {
  .gap-md-21 {
    gap: 21px; } }

.gap-22 {
  gap: 22px; }

@media (min-width: 768px) {
  .gap-md-22 {
    gap: 22px; } }

.gap-23 {
  gap: 23px; }

@media (min-width: 768px) {
  .gap-md-23 {
    gap: 23px; } }

.gap-24 {
  gap: 24px; }

@media (min-width: 768px) {
  .gap-md-24 {
    gap: 24px; } }

.gap-25 {
  gap: 25px; }

@media (min-width: 768px) {
  .gap-md-25 {
    gap: 25px; } }

.gap-26 {
  gap: 26px; }

@media (min-width: 768px) {
  .gap-md-26 {
    gap: 26px; } }

.gap-27 {
  gap: 27px; }

@media (min-width: 768px) {
  .gap-md-27 {
    gap: 27px; } }

.gap-28 {
  gap: 28px; }

@media (min-width: 768px) {
  .gap-md-28 {
    gap: 28px; } }

.gap-29 {
  gap: 29px; }

@media (min-width: 768px) {
  .gap-md-29 {
    gap: 29px; } }

.gap-30 {
  gap: 30px; }

@media (min-width: 768px) {
  .gap-md-30 {
    gap: 30px; } }

.gap-31 {
  gap: 31px; }

@media (min-width: 768px) {
  .gap-md-31 {
    gap: 31px; } }

.gap-32 {
  gap: 32px; }

@media (min-width: 768px) {
  .gap-md-32 {
    gap: 32px; } }

.gap-33 {
  gap: 33px; }

@media (min-width: 768px) {
  .gap-md-33 {
    gap: 33px; } }

.gap-34 {
  gap: 34px; }

@media (min-width: 768px) {
  .gap-md-34 {
    gap: 34px; } }

.gap-35 {
  gap: 35px; }

@media (min-width: 768px) {
  .gap-md-35 {
    gap: 35px; } }

.gap-36 {
  gap: 36px; }

@media (min-width: 768px) {
  .gap-md-36 {
    gap: 36px; } }

.gap-37 {
  gap: 37px; }

@media (min-width: 768px) {
  .gap-md-37 {
    gap: 37px; } }

.gap-38 {
  gap: 38px; }

@media (min-width: 768px) {
  .gap-md-38 {
    gap: 38px; } }

.gap-39 {
  gap: 39px; }

@media (min-width: 768px) {
  .gap-md-39 {
    gap: 39px; } }

.gap-40 {
  gap: 40px; }

@media (min-width: 768px) {
  .gap-md-40 {
    gap: 40px; } }

.gap-41 {
  gap: 41px; }

@media (min-width: 768px) {
  .gap-md-41 {
    gap: 41px; } }

.gap-42 {
  gap: 42px; }

@media (min-width: 768px) {
  .gap-md-42 {
    gap: 42px; } }

.gap-43 {
  gap: 43px; }

@media (min-width: 768px) {
  .gap-md-43 {
    gap: 43px; } }

.gap-44 {
  gap: 44px; }

@media (min-width: 768px) {
  .gap-md-44 {
    gap: 44px; } }

.gap-45 {
  gap: 45px; }

@media (min-width: 768px) {
  .gap-md-45 {
    gap: 45px; } }

.gap-46 {
  gap: 46px; }

@media (min-width: 768px) {
  .gap-md-46 {
    gap: 46px; } }

.gap-47 {
  gap: 47px; }

@media (min-width: 768px) {
  .gap-md-47 {
    gap: 47px; } }

.gap-48 {
  gap: 48px; }

@media (min-width: 768px) {
  .gap-md-48 {
    gap: 48px; } }

.gap-49 {
  gap: 49px; }

@media (min-width: 768px) {
  .gap-md-49 {
    gap: 49px; } }

.gap-50 {
  gap: 50px; }

@media (min-width: 768px) {
  .gap-md-50 {
    gap: 50px; } }

.gap-51 {
  gap: 51px; }

@media (min-width: 768px) {
  .gap-md-51 {
    gap: 51px; } }

.gap-52 {
  gap: 52px; }

@media (min-width: 768px) {
  .gap-md-52 {
    gap: 52px; } }

.gap-53 {
  gap: 53px; }

@media (min-width: 768px) {
  .gap-md-53 {
    gap: 53px; } }

.gap-54 {
  gap: 54px; }

@media (min-width: 768px) {
  .gap-md-54 {
    gap: 54px; } }

.gap-55 {
  gap: 55px; }

@media (min-width: 768px) {
  .gap-md-55 {
    gap: 55px; } }

.gap-56 {
  gap: 56px; }

@media (min-width: 768px) {
  .gap-md-56 {
    gap: 56px; } }

.gap-57 {
  gap: 57px; }

@media (min-width: 768px) {
  .gap-md-57 {
    gap: 57px; } }

.gap-58 {
  gap: 58px; }

@media (min-width: 768px) {
  .gap-md-58 {
    gap: 58px; } }

.gap-59 {
  gap: 59px; }

@media (min-width: 768px) {
  .gap-md-59 {
    gap: 59px; } }

.gap-60 {
  gap: 60px; }

@media (min-width: 768px) {
  .gap-md-60 {
    gap: 60px; } }

.gap-61 {
  gap: 61px; }

@media (min-width: 768px) {
  .gap-md-61 {
    gap: 61px; } }

.gap-62 {
  gap: 62px; }

@media (min-width: 768px) {
  .gap-md-62 {
    gap: 62px; } }

.gap-63 {
  gap: 63px; }

@media (min-width: 768px) {
  .gap-md-63 {
    gap: 63px; } }

.gap-64 {
  gap: 64px; }

@media (min-width: 768px) {
  .gap-md-64 {
    gap: 64px; } }

.gap-65 {
  gap: 65px; }

@media (min-width: 768px) {
  .gap-md-65 {
    gap: 65px; } }

.gap-66 {
  gap: 66px; }

@media (min-width: 768px) {
  .gap-md-66 {
    gap: 66px; } }

.gap-67 {
  gap: 67px; }

@media (min-width: 768px) {
  .gap-md-67 {
    gap: 67px; } }

.gap-68 {
  gap: 68px; }

@media (min-width: 768px) {
  .gap-md-68 {
    gap: 68px; } }

.gap-69 {
  gap: 69px; }

@media (min-width: 768px) {
  .gap-md-69 {
    gap: 69px; } }

.gap-70 {
  gap: 70px; }

@media (min-width: 768px) {
  .gap-md-70 {
    gap: 70px; } }

.gap-71 {
  gap: 71px; }

@media (min-width: 768px) {
  .gap-md-71 {
    gap: 71px; } }

.gap-72 {
  gap: 72px; }

@media (min-width: 768px) {
  .gap-md-72 {
    gap: 72px; } }

.opacity-dot-0 {
  opacity: 0; }

.opacity-dot-1 {
  opacity: 0.1; }

.opacity-dot-2 {
  opacity: 0.2; }

.opacity-dot-3 {
  opacity: 0.3; }

.opacity-dot-4 {
  opacity: 0.4; }

.opacity-dot-5 {
  opacity: 0.5; }

.opacity-dot-6 {
  opacity: 0.6; }

.opacity-dot-7 {
  opacity: 0.7; }

.opacity-dot-8 {
  opacity: 0.8; }

.opacity-dot-9 {
  opacity: 0.9; }

.opacity-dot-10 {
  opacity: 1; }

.font-weight-thin {
  font-weight: 200 !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-medium {
  font-weight: 500 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

@-webkit-keyframes fadein-down {
  from {
    top: -5px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@keyframes fadein-down {
  from {
    top: -5px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@-webkit-keyframes fadeout-down {
  from {
    top: 0;
    opacity: 1; }
  to {
    top: -5px;
    opacity: 0; } }

@keyframes fadeout-down {
  from {
    top: 0;
    opacity: 1; }
  to {
    top: -5px;
    opacity: 0; } }

@-webkit-keyframes fade-opacity {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-opacity {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.btn {
  color: #231f20;
  text-align: center;
  letter-spacing: 0.25px;
  outline: none !important;
  height: 48px;
  font-size: 14px;
  min-width: 210px;
  max-width: 290px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis; }
  .btn.disabled, .btn:disabled {
    background-color: rgba(102, 102, 102, 0.1) !important;
    color: #231f20 !important;
    border-color: transparent !important; }
    .btn.disabled:hover, .btn:disabled:hover {
      box-shadow: none !important; }
  @media (min-width: 768px) {
    .btn {
      font-size: 18px;
      line-height: 44px;
      padding: 0 20px;
      display: inline-block;
      width: auto; } }
  .btn.btn-default, .btn.btn-outline-secondary {
    background-color: transparent;
    color: #231f20;
    background-color: transparent;
    background-image: none;
    border-color: #979797; }
    html:not(.can-touch) .btn.btn-default:hover, html:not(.can-touch) .btn.btn-outline-secondary:hover {
      color: #231f20;
      background-color: #ffdb4c;
      border-color: #ffcc00; }
    .btn.btn-default:focus, .btn.btn-default.focus, .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary.focus {
      box-shadow: none; }
    .btn.btn-default.disabled, .btn.btn-default:disabled, .btn.btn-outline-secondary.disabled, .btn.btn-outline-secondary:disabled {
      color: #231f20;
      background-color: transparent; }
    .btn.btn-default:not(:disabled):not(.disabled):active, .btn.btn-default:not(:disabled):not(.disabled).active,
    .show > .btn.btn-default.dropdown-toggle, .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-secondary.dropdown-toggle {
      color: #231f20;
      background-color: #ffcc00;
      border-color: #ffcc00; }
      .btn.btn-default:not(:disabled):not(.disabled):active:focus, .btn.btn-default:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-default.dropdown-toggle:focus, .btn.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-secondary.dropdown-toggle:focus {
        box-shadow: none; }
    .btn.btn-default:hover, .btn.btn-default:active, .btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:active {
      color: #ffffff !important;
      background-color: rgba(250, 202, 10, 0.9) !important;
      box-shadow: 0px 2px 12px 4px rgba(255, 204, 0, 0.25); }
    .btn.btn-default.disabled, .btn.btn-outline-secondary.disabled {
      color: #231f20 !important; }
  .btn.btn-yellow, .btn.btn-primary {
    color: #212529;
    background-color: #ffcc00;
    border-color: #ffcc00;
    color: #231f20 !important; }
    .btn.btn-yellow:hover, .btn.btn-primary:hover {
      color: #212529;
      background-color: rgba(250, 202, 10, 0.9);
      border-color: rgba(250, 202, 10, 0.9); }
    .btn.btn-yellow:focus, .btn.btn-yellow.focus, .btn.btn-primary:focus, .btn.btn-primary.focus {
      color: #212529;
      background-color: rgba(250, 202, 10, 0.9);
      border-color: rgba(250, 202, 10, 0.9);
      box-shadow: 0 0 0 0 rgba(222, 179, 6, 0.5); }
    .btn.btn-yellow.disabled, .btn.btn-yellow:disabled, .btn.btn-primary.disabled, .btn.btn-primary:disabled {
      color: #212529;
      background-color: #ffcc00;
      border-color: #ffcc00; }
    .btn.btn-yellow:not(:disabled):not(.disabled):active, .btn.btn-yellow:not(:disabled):not(.disabled).active,
    .show > .btn.btn-yellow.dropdown-toggle, .btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-primary.dropdown-toggle {
      color: #212529;
      background-color: #ffcc00;
      border-color: #ffcc00; }
      .btn.btn-yellow:not(:disabled):not(.disabled):active:focus, .btn.btn-yellow:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-yellow.dropdown-toggle:focus, .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .btn.btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0 rgba(222, 179, 6, 0.5); }
    .btn.btn-yellow:hover, .btn.btn-primary:hover {
      color: #ffffff !important;
      box-shadow: 0px 2px 12px 4px rgba(255, 204, 0, 0.5); }
    .btn.btn-yellow.disabled, .btn.btn-primary.disabled {
      color: #231f20 !important; }

.btn-wrapper {
  width: 48.36957%;
  height: 60px; }
  @media screen and (max-width: 767px) {
    .btn-wrapper {
      width: 100% !important;
      height: 52px; } }

.btn.btn-with-icon {
  position: relative;
  text-align: left;
  min-width: 0;
  max-width: none;
  width: 100%;
  height: 100%;
  border: 0.5px solid #C7C7C7 !important;
  box-shadow: 1px 1px 4px 1px #C7C7C7;
  color: #231f20;
  background-color: transparent;
  background-image: none;
  border-color: #979797; }
  .btn.btn-with-icon.active {
    border: 3px solid #FFCC00 !important;
    box-shadow: none !important;
    background-color: #ffffff !important; }
  .btn.btn-with-icon:hover {
    border: 3px solid #FFCC00 !important; }
  .btn.btn-with-icon .icon {
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    background-size: contain; }
  .btn.btn-with-icon .icon-check {
    background-repeat: no-repeat;
    position: absolute;
    right: 20px;
    top: 45%;
    transform: translateY(-50%); }
  .btn.btn-with-icon span {
    font-style: normal;
    display: block;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 8px; }
  html:not(.can-touch) .btn.btn-with-icon:hover {
    color: #231f20;
    background-color: #ffdb4c;
    border-color: #ffcc00; }
  .btn.btn-with-icon:focus, .btn.btn-with-icon.focus {
    box-shadow: none; }
  .btn.btn-with-icon.disabled, .btn.btn-with-icon:disabled {
    color: #231f20;
    background-color: transparent; }
  .btn.btn-with-icon:not(:disabled):not(.disabled):active, .btn.btn-with-icon:not(:disabled):not(.disabled).active,
  .show > .btn.btn-with-icon.dropdown-toggle {
    color: #231f20;
    background-color: #ffcc00;
    border-color: #ffcc00; }
    .btn.btn-with-icon:not(:disabled):not(.disabled):active:focus, .btn.btn-with-icon:not(:disabled):not(.disabled).active:focus,
    .show > .btn.btn-with-icon.dropdown-toggle:focus {
      box-shadow: none; }
  @media (min-width: 768px) {
    .btn.btn-with-icon {
      font-size: 16px; } }
  .buttons .btn.btn-with-icon {
    margin: 0; }

.buttons {
  margin: 0px auto; }
  @media (min-width: 768px) {
    .buttons {
      margin: 0 auto; } }

.btn-common {
  max-width: none !important;
  color: #231F20 !important;
  font-size: 16px !important;
  padding: 0 24px !important; }
  .btn-common.btn {
    margin-top: 20px; }
  @media screen and (max-width: 767px) {
    .btn-common {
      width: 100% !important;
      max-width: none;
      padding: 0 40px; }
      .btn-common.btn {
        margin-right: 20px; } }
  .btn-common.btn-light {
    border: 1px solid #979797 !important;
    background-color: #ffffff; }
    .btn-common.btn-light:hover {
      background-color: rgba(250, 202, 10, 0.9) !important;
      border: 2px solid transparent !important;
      box-shadow: 0px 2px 12px 4px rgba(255, 204, 0, 0.5); }
    .btn-common.btn-light:disabled {
      border: none !important; }

.input-group-amount {
  position: relative; }
  .input-group-amount__currency {
    position: absolute;
    left: 1px;
    top: 3px;
    width: 25px;
    text-align: right; }
    @media (min-width: 768px) {
      .input-group-amount__currency {
        top: 1px; } }
    .input-group-amount__currency i {
      font-style: normal;
      display: inline-block;
      line-height: 33px;
      vertical-align: middle; }
      @media (min-width: 768px) {
        .input-group-amount__currency i {
          font-size: 18px;
          line-height: 46px; } }
  .input-group-amount .form-control {
    padding: 0 12px;
    padding-left: 28px;
    line-height: 34px; }

.form-control {
  background: #fff;
  border: 1px solid #7a7a7a;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px;
  color: #231f20;
  height: 40px;
  outline: none !important; }
  @media (min-width: 768px) {
    .form-control {
      max-width: 360px;
      height: 48px;
      font-size: 18px; } }

.form-check {
  padding-left: 0; }
  .form-check + .form-check {
    margin-top: 16px; }
  .form-check-input {
    display: none; }
  .form-check-label {
    color: #231f20;
    cursor: pointer; }
    .form-check-label:before {
      content: '';
      width: 24px;
      height: 24px;
      border: solid 2px #979797;
      display: inline-block;
      vertical-align: middle;
      margin-top: -3px;
      margin-right: 20px;
      background-color: transparent;
      box-shadow: 0 0 0 1px transparent;
      margin-left: 1px; }
  .form-check-input[type='radio'] + .form-check-label:before {
    border-radius: 50%; }
  .form-check-input[type='radio']:checked + .form-check-label:before {
    background-color: #ffcc00;
    border: 4px solid #fff;
    box-shadow: 0 0 0 2px #ffe066; }
  .form-check-input[type='checkbox'] + .form-check-label {
    padding-left: 38px;
    position: relative; }
    @media (min-width: 768px) {
      .form-check-input[type='checkbox'] + .form-check-label {
        padding-left: 50px; } }
    .form-check-input[type='checkbox'] + .form-check-label:before {
      border-radius: 0;
      position: absolute;
      left: 0;
      top: 0;
      margin: 1px 0 0;
      width: 20px;
      height: 20px; }
      @media (min-width: 768px) {
        .form-check-input[type='checkbox'] + .form-check-label:before {
          width: 24px;
          height: 24px; } }
  .form-check-input[type='checkbox']:checked + .form-check-label:before {
    content: '';
    border: 0;
    box-shadow: none;
    background: url("../assets/images/controls/checkbox_seleted.svg");
    background-size: contain; }

select.form-control {
  appearance: none;
  position: relative;
  background: url("../assets/images/controls/iconLinkDown.svg") no-repeat right 10px center;
  background-size: 14px 9px;
  padding-right: 28px;
  max-height: 400px;
  color: initial; }
  select.form-control::-ms-expand {
    display: none; }
  select.form-control.has-selected {
    color: #231f20; }
  @media (min-width: 768px) {
    select.form-control {
      padding-right: 34px; } }
  select.form-control .selector-text {
    color: #a69b9e !important; }

.form-group {
  margin-bottom: 24px;
  max-width: none; }
  .form-group + .buttons {
    margin-top: 30px; }

.dropdown button {
  width: 100%;
  max-width: none;
  min-width: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: white;
  gap: 8px; }
  @media (max-width: 767px) {
    .dropdown button {
      min-width: auto; } }

.dropdown .dropdown-menu {
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
  border: none; }
  .dropdown .dropdown-menu.dropdown-full-height {
    max-height: none; }

.dropdown .dropdown-toggle {
  color: #231F20 !important;
  background-color: #fff !important;
  border: 1px #979797 solid; }

.dropdown .dropdown-item {
  height: 40px;
  margin-bottom: 4px; }
  .dropdown .dropdown-item.selected {
    background-color: #ffd633;
    color: #fff; }

input::placeholder {
  color: #231f20;
  opacity: 0.65 !important; }

input::-ms-input-placeholder {
  color: #231f20;
  opacity: 0.65 !important; }

.label-title {
  font-size: 24px;
  color: #231F20;
  font-weight: 500; }
  .label-title.light {
    color: #fff; }
  .label-title.small {
    font-size: 16px; }
  @media (max-width: 767px) {
    .label-title {
      font-size: 20px; }
      .label-title.small {
        font-size: 16px; } }

.pl-web-container {
  padding: 48px 0px !important; }
  .pl-web-container .match-fill {
    width: 100%; }
  @media (max-width: 767px) {
    .pl-web-container {
      padding: 24px 16px 0px 16px !important; }
      .pl-web-container .match-fill {
        width: calc(100% + 64px) !important; } }

.pl-container-page {
  padding-top: 48px;
  padding-bottom: 48px; }
  @media (max-width: 767px) {
    .pl-container-page {
      padding-top: 24px;
      padding-bottom: 24px; } }
  .pl-container-page.page-empty {
    padding: 0px !important;
    opacity: 0; }

.pl-container-highlight {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F4F4F4;
  padding-top: 48px;
  padding-bottom: 48px; }
  @media (max-width: 767px) {
    .pl-container-highlight {
      padding-top: 24px;
      padding-bottom: 24px; } }

.background-highlight {
  background-color: #F4F4F4; }

.tip-guilde {
  color: #979797 !important;
  font-size: 14px !important;
  padding: 8px 0;
  clear: both; }

.step-point {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #E8E8E8;
  display: flex;
  justify-content: center;
  align-items: center; }

.wrapper-modal-title {
  font-size: 24px;
  color: #4F4C4D;
  text-align: center; }

.label-guilde {
  color: #4F4C4D;
  font-weight: 300;
  font-size: 16px !important;
  margin-bottom: 0.25rem; }
  @media (max-width: 767px) {
    .label-guilde {
      font-size: 14px !important; } }

.label-error {
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: #FF001F;
  margin: 16px 0px; }

.pl-shadow {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px; }

a.no-link {
  cursor: pointer; }
  a.no-link span {
    font-weight: bold; }
  a.no-link.disabled {
    cursor: auto; }

.underline {
  text-decoration: underline; }
  .underline:hover {
    text-decoration: underline; }

.hidden-input {
  opacity: 0;
  z-index: -1;
  position: absolute;
  left: -1px; }

.solid-line {
  border-bottom: 1px solid #C7C7C7; }

.sub-guilde {
  color: #7B7B7B; }

.sub-label {
  color: #C7C7C7; }

.sub-title {
  color: #8B8B8B; }

.money-text {
  color: #5D5D5D; }

.cursor-pointer {
  cursor: pointer; }

.text-underline {
  text-decoration: underline; }

@media screen and (max-width: 767px) {
  .wrap-svg-icon {
    padding: 16px; } }

.line-height-1 {
  line-height: 1; }

.line-height-2 {
  line-height: 1.2; }

.box-boder {
  border: 1px solid #C7C7C7; }

.help-tip-content {
  display: flex !important; }

.react-loading-skeleton {
  background-color: #ECE9E9 !important;
  --highlight-color: #fff !important;
  border-radius: 6px !important;
  top: -2px !important; }
  .react-loading-skeleton.dark-mode {
    background-color: #15202B !important;
    --highlight-color: lighten(#15202B, 40%) !important; }

.pl-slider {
  width: 98%;
  padding: 8px 0px; }
  .pl-slider .rc-slider-handle {
    border-color: radial-gradient(50% 50% at 75% 75%, #ffffff 0%, rgba(255, 84, 238, 0) 100%) !important;
    background: #FFCC00 !important;
    box-shadow: 0px 0px 16px 8px #ffffff;
    border: solid 2px #ffffff;
    margin-top: -8px;
    margin-left: -12px;
    width: 24px;
    height: 24px; }
    .pl-slider .rc-slider-handle:hover, .pl-slider .rc-slider-handle:active {
      border-color: #ffffff; }
  .pl-slider .rc-slider-dot {
    opacity: 0 !important; }
  .pl-slider .rc-slider-rail {
    background-color: #5D5D5D !important;
    height: 8px; }
  .pl-slider .rc-slider-track {
    background: #FFCC00 !important;
    height: 8px;
    box-shadow: 0px 0px 6px 3px #ffffff; }
  .pl-slider .rc-slider-mark {
    display: flex;
    justify-content: space-between; }
    .pl-slider .rc-slider-mark .rc-slider-mark-text {
      position: relative;
      width: auto !important;
      margin: 0 !important;
      left: 0 !important;
      right: 0 !important; }
  @media (max-width: 767px) {
    .pl-slider {
      height: 24px; }
      .pl-slider .rc-slider-track {
        height: 10px; }
      .pl-slider .rc-slider-step {
        height: 10px; }
      .pl-slider .rc-slider-rail {
        height: 10px; } }

.autocomplete {
  position: relative;
  background-color: #ECECEC;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  border-radius: 4px; }
  .autocomplete.autocomplete-hide-search input {
    width: 100%; }
  .autocomplete input {
    width: calc(100% - 72px);
    background-color: transparent;
    border: none;
    color: #9E9E9E;
    outline: none;
    font-size: 16px;
    height: 48px;
    margin: 4px; }
    @media screen and (max-width: 767px) {
      .autocomplete input {
        margin: 0px 4px; } }
  .autocomplete .autocomplete_loading {
    width: 30px;
    height: 30px; }
  .autocomplete .autocomplete-search-icon__active {
    cursor: pointer !important; }
    .autocomplete .autocomplete-search-icon__active.search-icon__busy {
      cursor: progress; }
  .autocomplete .autocomplete-items {
    max-height: 420px;
    overflow-y: scroll; }
  .autocomplete .autocomplete_clear {
    cursor: pointer;
    opacity: 0.5; }
    .autocomplete .autocomplete_clear:hover {
      opacity: 1; }
  @media screen and (max-width: 767px) {
    .autocomplete {
      padding-right: 8px; } }

.truid-form-address .pl-dropdown-item {
  outline: none !important; }

.truid-form-address .pl-dropdown-item:hover {
  background-color: #e9e9e9; }

.truid-form-address .pl-dropdown-item.selected {
  background-color: #ffd633;
  color: #fff !important; }

.has-error .autocomplete-base-input {
  border: 2px #ff001f solid !important; }

.has-error .dropdown-toggle {
  border: 2px #ff001f solid !important; }

.autocomplete-base-input {
  background-color: transparent;
  border: 1px #979797 solid;
  padding: 0px !important;
  padding-right: 12px !important;
  max-height: 320px; }
  .autocomplete-base-input .autocomplete-items {
    top: 103%; }
  @media screen and (max-width: 767px) {
    .autocomplete-base-input {
      padding-right: 8px !important; } }
  .autocomplete-base-input input {
    color: #231f20;
    font-size: 18px;
    margin: 4px 12px;
    height: 40px; }
    @media screen and (max-width: 767px) {
      .autocomplete-base-input input {
        font-size: 16px;
        margin: 0px 8px; } }

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0; }

.autocomplete-items div {
  padding: 8px 16px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4; }
  .autocomplete-items div.autocomplete-items-empty {
    cursor: default;
    color: rgba(158, 158, 158, 0.65); }
    .autocomplete-items div.autocomplete-items-empty:hover {
      background-color: #fff; }

.autocomplete-items div:hover {
  background-color: #e9e9e9; }

.autocomplete-active {
  background-color: #e9ecef !important; }

/* react auto suggest */
.react-autosuggest__container {
  position: relative; }

.react-autosuggest__input {
  width: 100%;
  height: 48px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 18px;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 49px;
  width: 100%;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 18px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 300px;
  overflow-y: auto; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 16px;
  border-bottom: 1px solid #d4d4d4; }

.react-autosuggest__suggestion--highlighted {
  color: #ffffff;
  background-color: DodgerBlue; }
  .react-autosuggest__suggestion--highlighted:hover {
    color: #9E9E9E;
    background-color: #e9e9e9 !important; }

.pl-accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 16px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18); }
  @media (max-width: 767px) {
    .pl-accordion {
      padding: 24px 16px; }
      .pl-accordion .pl-accordion-title {
        flex-direction: column !important; } }
  .pl-accordion .pl-accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: #231F20; }
    @media (max-width: 767px) {
      .pl-accordion .pl-accordion-title {
        align-items: center; } }
    .pl-accordion .pl-accordion-title .title {
      display: inline-block;
      cursor: pointer;
      font-weight: 500;
      user-select: none; }

.pl-accordion-arrow {
  cursor: pointer;
  transition: all 0.5s; }
  .pl-accordion-arrow:hover {
    opacity: 0.5; }
  .pl-accordion-arrow.arrow-active {
    transform: rotate(-180deg);
    transition: all 0.5s; }

.pl-accordion-content {
  padding-top: 32px;
  visibility: visible;
  -webkit-animation: fadein-down 1s;
  animation: fadein-down 1s; }
  @media (max-width: 767px) {
    .pl-accordion-content {
      padding-top: 16px; } }

.action-step-list {
  gap: 32px; }
  .action-step-list > div {
    position: relative;
    flex-basis: 25%; }
  .action-step-list .step-item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center; }
    .action-step-list .step-item-info * {
      font-size: 16px; }
    @media (max-width: 767px) {
      .action-step-list .step-item-info {
        flex-direction: row;
        gap: 16px; }
        .action-step-list .step-item-info .step-item-description {
          text-align: left;
          width: calc(100% - 100px); }
          .action-step-list .step-item-info .step-item-description * {
            font-size: 14px !important; } }
  .action-step-list .step-item-description {
    width: 100%; }
    @media (max-width: 767px) {
      .action-step-list .step-item-description {
        width: calc(100% - 84px); } }
  .action-step-list .action-step-mobile:not(.action-step-last)::before,
  .action-step-list .action-step-web:not(.action-step-last)::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 49px;
    width: 100%;
    height: 3px;
    background: #e8e8e8;
    -webkit-animation: fade-opacity 2.5s;
    animation: fade-opacity 2.5s; }
    @media (max-width: 767px) {
      .action-step-list .action-step-mobile:not(.action-step-last)::before,
      .action-step-list .action-step-web:not(.action-step-last)::before {
        left: 30px;
        width: 3px;
        height: 100%; } }
  .action-step-list.step-list-no-line > div::before {
    display: none !important;
    opacity: 0 !important; }
  @media (max-width: 767px) {
    .action-step-list {
      gap: 24px;
      flex-direction: column !important; } }
  .action-step-list.intro-truid-steps > div, .action-step-list.debicheck-steps > div, .action-step-list.intro-selfie-steps > div {
    flex-basis: calc(100% / 3); }
  @media (max-width: 767px) {
    .action-step-list.intro-truid-steps, .action-step-list.debicheck-steps, .action-step-list.intro-selfie-steps {
      padding: 0px; }
      .action-step-list.intro-truid-steps .step-item-info, .action-step-list.debicheck-steps .step-item-info, .action-step-list.intro-selfie-steps .step-item-info {
        align-items: flex-start; } }
  .action-step-list.offers-steps > div {
    flex-basis: calc(100% / 2); }
  .action-step-list.step-list-verical {
    flex-direction: column; }
    .action-step-list.step-list-verical .step-item-info {
      flex-direction: row !important; }
    .action-step-list.step-list-verical .step-item-description {
      text-align: left;
      width: calc(100% - 100px); }

.money-label {
  display: inline-block; }
  .money-label.primary-color {
    color: #FFCC00; }
  .money-label.light-color {
    color: #ffffff; }

.label-money-item.border-bottom {
  border-bottom: 1px solid #5D5D5D !important;
  padding-bottom: 16px; }

.month-terms {
  width: 126px !important;
  height: 98px !important;
  border: 1px solid #757575;
  line-height: 1;
  gap: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  user-select: none; }
  .month-terms.active {
    border: 3px solid #FFCC00;
    box-shadow: 0px 2px 16px 8px rgba(255, 204, 0, 0.2); }
  .month-terms .month-tick-icon {
    top: -12px;
    right: -12px; }
  .month-terms:hover {
    border: 3px solid #FFCC00; }

.nav-logo {
  background-repeat: no-repeat !important;
  background-size: contain;
  width: 134px;
  height: 32px;
  cursor: pointer; }

.nav-split {
  border: 0.88px solid #CACACA;
  height: 32px; }

.logo-tymebank-white {
  background: url(../assets/images/x2-icon/logo-tymebank-white.svg); }

.logo-tfg-white {
  background: url(../assets/images/x2-icon/logo-tfg-white.svg); }

.logo-tymebank-black {
  background: url(../assets/images/x2-icon/logo-tymebank-black.svg); }

.logo-tfg-black {
  background: url(../assets/images/x2-icon/logo-tfg-black.svg); }

@keyframes loading-ring-async {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.loading-ring-async,
.loading-ring-async div,
.loading-ring-async div:after {
  box-sizing: border-box; }

.loading-ring-async {
  color: #8E8E93;
  display: inline-block;
  position: relative; }
  .loading-ring-async div {
    transform-origin: 16px 16px;
    animation: loading-ring-async 1.2s linear infinite; }
  .loading-ring-async div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 1px;
    left: 12px;
    width: 3px;
    height: 8px;
    border-radius: 20%;
    background: #8E8E93; }
  .loading-ring-async div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s; }
  .loading-ring-async div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s; }
  .loading-ring-async div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s; }
  .loading-ring-async div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s; }
  .loading-ring-async div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s; }
  .loading-ring-async div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s; }
  .loading-ring-async div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s; }
  .loading-ring-async div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s; }
  .loading-ring-async div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s; }
  .loading-ring-async div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s; }
  .loading-ring-async div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s; }
  .loading-ring-async div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s; }

.btn-loading-async {
  min-width: 156px !important; }
  .btn-loading-async .loading-ring-async {
    top: -16px;
    left: -16px; }
  .btn-loading-async.async-loading {
    cursor: default !important;
    box-shadow: none !important;
    border: none !important; }
    .btn-loading-async.async-loading:hover.btn-default {
      background-color: #ffffff !important;
      border: none !important; }
    .btn-loading-async.async-loading:hover.btn-yellow {
      background-color: #ffd633 !important; }
    .btn-loading-async.async-loading:hover.btn-light {
      background-color: #ffffff !important; }

.pl-container-fluid {
  position: relative;
  overflow: hidden; }

html {
  min-height: 100%;
  position: relative; }

.page-footer {
  border-top: 2px solid #FECC05;
  background-color: #1A1A1A;
  font-size: 16px;
  color: #FFFFFF;
  padding-top: 48px; }
  .page-footer .footer-guidle {
    display: flex;
    flex-direction: row;
    gap: 48px;
    padding-top: 48px;
    padding-bottom: 48px; }
    .page-footer .footer-guidle.lock-footer {
      gap: 36px;
      align-items: center; }
  .page-footer .copyright {
    position: relative;
    font-size: 14px; }
    .page-footer .copyright a {
      color: #FFFFFF;
      text-decoration: none; }
    .page-footer .copyright a:hover {
      color: #FFCC00;
      text-decoration: underline; }
  .page-footer .line {
    position: relative;
    height: 16px; }
    .page-footer .line .container {
      position: relative; }
  .page-footer .anchor-top {
    position: absolute;
    margin-top: -88px;
    right: 0px;
    width: 72px;
    height: 72px;
    background: #FFCC00;
    color: #231F20;
    border-radius: 4px;
    cursor: pointer; }
    .page-footer .anchor-top:hover {
      color: #FFFFFF; }
      .page-footer .anchor-top:hover path {
        fill: #FFFFFF; }
  .page-footer .social-contact {
    padding: 0px 0px 32px 0px; }
    .page-footer .social-contact > div {
      display: flex;
      gap: 8px; }
    .page-footer .social-contact .social-icon {
      margin-top: -2px; }
  .page-footer .policy-info {
    color: #8B8B8B;
    padding: 24px 0px;
    display: flex;
    flex-direction: row; }
  .page-footer .description-info {
    color: #8B8B8B; }
    .page-footer .description-info a {
      color: #8B8B8B !important; }
    .page-footer .description-info a:hover {
      color: #FFCC00 !important; }
    .page-footer .description-info .linkColor {
      color: #3eb5e5 !important;
      text-decoration: underline; }
  @media screen and (max-width: 767px) {
    .page-footer {
      font-size: 14px;
      padding-top: 24px; }
      .page-footer .footer-guidle {
        gap: 16px !important;
        padding-top: 24px;
        padding-bottom: 24px; }
      .page-footer .logo-footer {
        padding-right: 0; }
        .page-footer .logo-footer img {
          max-width: 100%;
          max-height: 30px; }
      .page-footer .copyright {
        text-align: left;
        padding-top: 12px; }
      .page-footer .line {
        height: 8px; }
      .page-footer .anchor-top {
        width: 56px;
        height: 56px;
        right: 0%;
        transform: translate(0%, -112%); }
      .page-footer .policy-info {
        padding: 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px; }
      .page-footer .social-contact {
        padding: 0px 0px 24px 0px; } }

.landing-banner {
  background-color: #141414;
  color: #fff;
  margin-bottom: 0; }
  .landing-banner .container {
    position: relative;
    min-height: 320px; }
  .landing-banner .top-banner-page {
    display: flex;
    justify-content: flex-start;
    background-color: #231f20;
    padding: 16px 20px; }
  .landing-banner .tfg-logo {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid white; }
  .landing-banner .banner-header-text {
    padding: 16px 0;
    min-height: 98px;
    width: 70%;
    margin-top: 24px; }
    .landing-banner .banner-header-text span {
      font-weight: 500;
      display: inline-block; }
  .landing-banner .banner-bg {
    position: absolute;
    right: 15px;
    bottom: 0;
    top: 0;
    left: 15px;
    background: 100% 100% no-repeat;
    background-size: contain; }
  .landing-banner .col-8 {
    padding: 0px !important; }
  .landing-banner .banner-header-title {
    font-size: 20px;
    color: #fff;
    margin: 12px 0 8px;
    font-weight: 500; }
  @media screen and (max-width: 767px) {
    .landing-banner .banner-bg {
      right: -100px !important; }
    .landing-banner .banner-header-title {
      width: 88%; }
    .landing-banner .banner-header-content {
      font-size: 14px;
      width: 84%; } }
  @media (min-width: 768px) {
    .landing-banner {
      margin-bottom: 0px; }
      .landing-banner .banner-header-content {
        font-size: 20px;
        padding-right: 320px;
        font-weight: 200;
        min-height: 30px; }
      .landing-banner .banner-header-text {
        padding: 0;
        width: 100%; }
      .landing-banner .banner-header-title {
        font-size: 40px;
        margin: 20px 0;
        font-weight: 300;
        max-width: 75%; } }

#wrapperMinHeight {
  overflow: hidden;
  min-height: 0;
  transform: translateZ(0);
  transition: min-height 0.1s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#wrapperRealHeight {
  overflow: hidden; }
  #wrapperRealHeight .step {
    overflow: hidden;
    padding-top: 20px;
    animation: 0.8s ease-out fadeIn;
    transform: translateZ(0); }

@media (max-width: 767px) {
  .list-tab-steps {
    display: none;
    opacity: 0;
    z-index: -1; } }

.tab-step {
  width: 100%;
  color: #231F20;
  padding-top: 24px;
  padding-left: 0;
  padding-right: 0; }
  .tab-step .tab-item {
    gap: 8px;
    width: 100%;
    text-align: center;
    margin: 0px 2px; }
  .tab-step .tab-border {
    width: 100%;
    border-bottom: solid 8px rgba(107, 117, 125, 0.5);
    box-shadow: 0px 2px 12px 4px rgba(107, 117, 125, 0.25); }
  .tab-step .tab-active {
    font-weight: bold; }
    .tab-step .tab-active .tab-border {
      border-color: #FDC600;
      box-shadow: 0px 2px 12px 4px rgba(255, 204, 0, 0.75); }
  .tab-step .tab-completed .tab-border {
    border-color: rgba(107, 117, 125, 0.75);
    box-shadow: 0px 2px 12px 4px rgba(107, 117, 125, 0.25); }

.navbar-default {
  padding: 12px;
  background: #231f20;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .navbar-default .container div img {
      height: 24px; } }

#landing {
  padding: 0px;
  max-width: none !important;
  text-align: center;
  padding-bottom: 0px !important; }
  #landing .landing-base {
    padding: 48px 0px;
    gap: 32px; }
    @media (max-width: 768px) {
      #landing .landing-base {
        padding: 24px 16px;
        gap: 16px; } }
  #landing .row {
    margin: 0px;
    padding-right: 0px !important;
    padding-left: 0px !important; }
  #landing .landing-title {
    max-width: 760px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding-bottom: 32px; }
    @media (max-width: 768px) {
      #landing .landing-title {
        gap: 16px;
        padding-bottom: 24px; } }
  #landing .landing-calculator {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #F4F4F4;
    color: #fff; }
    @media (max-width: 767px) {
      #landing .landing-calculator > .container {
        flex-direction: column;
        gap: 4px; } }
    #landing .landing-calculator .landing-loan-select {
      display: flex;
      flex-direction: column;
      gap: 56px;
      flex-basis: 50%;
      height: 386px;
      padding: 48px;
      padding-right: 96px;
      background: #231F20; }
      @media (max-width: 768px) {
        #landing .landing-calculator .landing-loan-select {
          width: 100%;
          padding: 24px 16px;
          gap: 24px;
          height: 312px; } }
      #landing .landing-calculator .landing-loan-select .landing-month-view {
        display: flex;
        gap: 12px;
        padding-top: 16px; }
      #landing .landing-calculator .landing-loan-select .month-terms {
        width: 126px;
        height: 98px;
        border: 1px solid #757575;
        border-radius: 8px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        user-select: none; }
        #landing .landing-calculator .landing-loan-select .month-terms.active {
          border: 3px solid #FFCC00;
          box-shadow: 0px 2px 16px 8px rgba(255, 204, 0, 0.2); }
        #landing .landing-calculator .landing-loan-select .month-terms .month-tick-icon {
          top: -12px;
          right: -12px; }
        #landing .landing-calculator .landing-loan-select .month-terms:hover {
          border: 3px solid #FFCC00; }
    #landing .landing-calculator .landing-month-detail {
      flex-basis: 45%; }
      #landing .landing-calculator .landing-month-detail > div {
        height: 346px;
        margin-left: -64px;
        background: #FFFFFF;
        color: #231F20;
        padding: 32px 48px;
        font-size: 16px; }
        @media (max-width: 768px) {
          #landing .landing-calculator .landing-month-detail > div {
            width: 100%;
            height: auto;
            margin-left: 0px;
            padding: 24px 16px; }
            #landing .landing-calculator .landing-month-detail > div .special {
              font-size: 24px !important; } }
        #landing .landing-calculator .landing-month-detail > div .month-item-detail {
          margin: -8px 0px; }
          #landing .landing-calculator .landing-month-detail > div .month-item-detail .col-6,
          #landing .landing-calculator .landing-month-detail > div .month-item-detail .col-7,
          #landing .landing-calculator .landing-month-detail > div .month-item-detail .col-5 {
            padding-right: 0px;
            padding-left: 0px; }
          #landing .landing-calculator .landing-month-detail > div .month-item-detail .text-right {
            font-weight: 500; }
            #landing .landing-calculator .landing-month-detail > div .month-item-detail .text-right.special {
              font-size: 32px; }
        #landing .landing-calculator .landing-month-detail > div .month-item-tip {
          margin-top: 16px;
          display: flex;
          gap: 16px;
          align-items: center; }
  #landing .landing-tip {
    display: flex;
    flex-direction: column; }
    #landing .landing-tip .landing-step {
      align-items: center; }
    #landing .landing-tip .action-step-item:not(.action-step-last)::before {
      -webkit-animation: fade-opacity 4s;
      animation: fade-opacity 4s; }
  #landing .landing-content-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #F4F4F4; }

.top-banner {
  height: 400px;
  background-image: url(../assets/images/landingPage.png);
  color: white;
  background-size: cover;
  background-position: center; }
  @media screen and (max-width: 767px) {
    .top-banner {
      height: 300px;
      background-image: url(../assets/images/landingPageMobile.png); } }
  .top-banner .container {
    position: relative;
    height: 100%;
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .top-banner .container {
        height: 300px; } }
    .top-banner .container .top-banner-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      gap: 16px;
      margin: 0px 32px; }
      .top-banner .container .top-banner-info .top-banner-text:first-child {
        margin-top: -16px; }
      .top-banner .container .top-banner-info h1 {
        font-size: 48px;
        font-weight: 500;
        margin-top: 30px;
        margin-bottom: 30px; }
      .top-banner .container .top-banner-info p {
        margin-top: -20px;
        font-size: 20px;
        margin-bottom: 0;
        max-width: 32%; }
      @media screen and (max-width: 767px) {
        .top-banner .container .top-banner-info {
          margin: 0px 8px; }
          .top-banner .container .top-banner-info h1 {
            font-size: 20px; }
          .top-banner .container .top-banner-info p {
            font-size: 14px;
            max-width: 70%; } }

@media (max-width: 767.98px) {
  .modal-dialog {
    height: 100vh; } }

@keyframes animationSpin {
  100% {
    background-position: -3744px 0; } }

@keyframes animationSpinMd {
  100% {
    background-position: -6656px 0; } }

.over-loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  color: #FFFFFF; }
  .over-loading-wrap .spinner-wrapper {
    position: absolute;
    width: 424px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 767px) {
      .over-loading-wrap .spinner-wrapper {
        width: 100%;
        padding: 0 16px; } }
  .over-loading-wrap .spinner {
    display: block;
    height: 72px;
    width: 72px;
    background-size: 3744px auto;
    animation: animationSpin 1s steps(52) infinite; }
    @media (min-width: 768px) {
      .over-loading-wrap .spinner {
        width: 128px;
        height: 128px;
        background-size: 6656px auto;
        animation: animationSpinMd 1s steps(52) infinite; } }

input.opt2-input-item.otp-number {
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #7a7a7a;
  font-size: 20px;
  color: #555; }
  input.opt2-input-item.otp-number.otp2-input-error {
    border-width: 2px !important; }
  input.opt2-input-item.otp-number:focus {
    border-color: #ffcc00;
    border-width: 2px;
    box-shadow: none; }

.loan-agreement {
  padding-bottom: 8px; }
  .loan-agreement .pl-loan-agreement {
    padding: 0px !important; }
  .loan-agreement hr.dash-line {
    border-top: 1px dashed #C7C7C7; }
  .loan-agreement .item-info {
    display: flex;
    font-weight: 300 !important;
    opacity: 0.8;
    font-size: 15px !important; }
  .loan-agreement .container.pl-container-page {
    padding: 0px !important;
    font-size: 16px !important; }
    .loan-agreement .container.pl-container-page .block-priority hr {
      display: none; }
    .loan-agreement .container.pl-container-page .block-priority .item-info * {
      font-weight: 300 !important; }
    .loan-agreement .container.pl-container-page .label-title {
      font-size: 24px;
      font-weight: 700;
      text-align: left !important;
      opacity: 1 !important;
      font-style: normal !important; }
    .loan-agreement .container.pl-container-page .item-title {
      font-weight: 700 !important;
      padding-top: 8px; }
    .loan-agreement .container.pl-container-page .item-line {
      border-top: 1px dashed #C7C7C7; }
    .loan-agreement .container.pl-container-page .condition-underline {
      text-decoration: underline; }
    .loan-agreement .container.pl-container-page .condition-italic {
      font-style: italic; }
    .loan-agreement .container.pl-container-page .condition-bolder {
      font-weight: 500; }
    .loan-agreement .container.pl-container-page .mr-1 {
      margin-right: 6px !important; }
    .loan-agreement .container.pl-container-page .mr-2 {
      margin-right: 16px !important; }
    .loan-agreement .container.pl-container-page .mr-3 {
      margin-right: 30px !important; }
    .loan-agreement .container.pl-container-page .mr-4 {
      margin-right: 38px !important; }
    .loan-agreement .container.pl-container-page .mr-5 {
      margin-right: 48px !important; }
    .loan-agreement .container.pl-container-page .mr-6 {
      margin-right: 60px !important; }
    .loan-agreement .container.pl-container-page .ml-6 {
      padding-left: 80px;
      margin-bottom: 0px; }
    .loan-agreement .container.pl-container-page .condition-defination-2 {
      display: flex;
      width: 100%;
      gap: 0px !important;
      flex-direction: column;
      margin-top: 4px; }
      .loan-agreement .container.pl-container-page .condition-defination-2 hr {
        display: none !important; }
      .loan-agreement .container.pl-container-page .condition-defination-2 .defination-title {
        width: 100% !important;
        min-width: 30%;
        font-weight: 500;
        white-space: pre-line; }
      .loan-agreement .container.pl-container-page .condition-defination-2 p {
        margin-bottom: 0px !important; }
        .loan-agreement .container.pl-container-page .condition-defination-2 p.defination-content {
          margin-bottom: 1rem !important;
          opacity: 0.8 !important;
          font-size: 15px !important; }
    .loan-agreement .container.pl-container-page p {
      margin-bottom: 0.75rem; }
    .loan-agreement .container.pl-container-page a {
      opacity: 1 !important;
      word-break: break-all;
      cursor: pointer;
      color: #3eb5e5 !important;
      text-decoration: underline !important; }
      .loan-agreement .container.pl-container-page a:hover {
        text-decoration: none !important; }
    .loan-agreement .container.pl-container-page b {
      font-weight: bold !important; }

#financial-information .field-balance:empty {
  display: none !important; }

@media (min-width: 768px) {
  #financial-information .field-balance {
    max-width: 730px; } }

#financial-information .field-balance.has-error .label {
  color: #ff001f; }

#confirmation-page hr {
  height: 1px;
  background-color: #d1d1d1;
  border: 0; }

#confirmation-page .wrap-section {
  justify-content: space-between; }

#confirmation-page .confirmation-item {
  width: 50%;
  margin-bottom: 20px;
  gap: 4px;
  font-size: 16px; }
  #confirmation-page .confirmation-item .item-label {
    font-weight: 500;
    margin: 0; }
  #confirmation-page .confirmation-item .item-value {
    margin: 0; }
  @media (max-width: 767px) {
    #confirmation-page .confirmation-item {
      margin-bottom: 12px;
      font-size: 14px; } }

#confirmation-page .wrap-confirmation-item {
  flex-direction: column; }
  @media (max-width: 767px) {
    #confirmation-page .wrap-confirmation-item {
      flex-direction: row;
      width: 100%;
      justify-content: space-between; } }

#offers .choices {
  margin-left: -15px;
  margin-right: -15px; }

#offers .choice {
  border-radius: 24px;
  background-color: #ffffff;
  box-shadow: 4px 4px 16px 4px #C7C7C7;
  position: relative;
  overflow: hidden; }
  @media (min-width: 767px) and (max-width: 1023px) {
    #offers .choice {
      margin: 0 auto; } }
  #offers .choice .btn {
    width: 100%;
    max-width: none; }

#offers .choice-package {
  background-color: #F5F5F5;
  border: solid 3px transparent;
  box-shadow: 4px 4px 16px 4px #C7C7C7;
  user-select: none; }
  #offers .choice-package:hover {
    border: solid 4px #FDC600;
    box-shadow: none !important; }

#offers .choice-package-border {
  background-color: #ffffff;
  border: solid 4px #FDC600;
  box-shadow: none !important; }

#offers .icon-radio {
  width: 24px;
  height: 24px;
  right: 0px;
  top: 4px; }

.modal-plan-details {
  max-width: 792px !important;
  width: 100% !important; }
  .modal-plan-details .modal-button-cancel {
    min-width: 280px; }
  .modal-plan-details .modal-button-ok {
    min-width: 220px; }
  .modal-plan-details .modal-popup-body {
    padding: 24px 32px !important; }
    @media (max-width: 768px) {
      .modal-plan-details .modal-popup-body {
        padding: 16px 24px !important; } }
  .modal-plan-details .plan-details .chartjs {
    width: 100%;
    height: 400px;
    padding-right: 24px; }
    .modal-plan-details .plan-details .chartjs .plan-total-repayment {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    @media (max-width: 767px) {
      .modal-plan-details .plan-details .chartjs {
        padding-right: 0px !important;
        margin-bottom: -16px; } }
  .modal-plan-details .plan-details .base {
    color: #005CCC !important; }
  .modal-plan-details .money-value {
    color: #1A1A1A; }
  .modal-plan-details .sub-label {
    margin-left: 32px !important; }
    @media (max-width: 767px) {
      .modal-plan-details .sub-label {
        margin-left: 24px !important; } }
  .modal-plan-details .border-bottom {
    border-width: 0.1px !important;
    padding-bottom: 12px; }

.app-mobile-tyme-icon {
  background-image: url(../assets/images/app-tyme-bank-icon.svg);
  background-size: cover;
  background-position: center;
  width: 64px;
  height: 64px; }
  @media screen and (max-width: 767px) {
    .app-mobile-tyme-icon {
      width: 40px;
      height: 40px;
      padding: 16px;
      background-image: url(../assets/images/app-tyme-bank-icon.svg); } }

#offers .pl-slider {
  padding: 0px !important; }
  #offers .pl-slider .rc-slider-track,
  #offers .pl-slider .rc-slider-rail,
  #offers .pl-slider .rc-slider-step {
    height: 14px; }
  #offers .pl-slider .rc-slider-handle {
    margin-top: -6px !important;
    width: 28px;
    height: 28px; }

#offers .rc-slider-track {
  box-shadow: 0px 0px 12px 3px rgba(255, 204, 0, 0.4); }

.modal {
  display: flex !important;
  align-items: center;
  color: #4f4c4d; }
  .modal .modal-dialog {
    margin: auto;
    padding: 16px 0px; }
    @media screen and (min-width: 768px) {
      .modal .modal-dialog {
        max-width: 620px;
        width: 620px; } }
    @media screen and (max-width: 767px) {
      .modal .modal-dialog {
        padding: 24px 16px;
        width: 100%; } }
    .modal .modal-dialog .modal-content {
      border: none;
      border-radius: 4px; }
      .modal .modal-dialog .modal-content .modal-header {
        position: relative;
        background: #231f20;
        color: white;
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 4px 4px 0 0; }
        .modal .modal-dialog .modal-content .modal-header h5 {
          width: 100%;
          font-size: 14px; }
          .modal .modal-dialog .modal-content .modal-header h5 img {
            margin-bottom: 8px; }
          .modal .modal-dialog .modal-content .modal-header h5 span {
            display: block;
            font-weight: 300; }
        .modal .modal-dialog .modal-content .modal-header a.close-btn {
          position: absolute;
          right: 12px;
          top: 20px;
          z-index: 1000;
          cursor: pointer; }
        @media screen and (min-width: 768px) {
          .modal .modal-dialog .modal-content .modal-header {
            padding-right: 60px; }
            .modal .modal-dialog .modal-content .modal-header h5 {
              text-align: left;
              display: flex;
              align-items: center;
              font-size: 20px; }
              .modal .modal-dialog .modal-content .modal-header h5 img.icon {
                margin-right: 16px;
                margin-left: 34px;
                margin-bottom: 0; }
              .modal .modal-dialog .modal-content .modal-header h5 span {
                display: inline; } }
      .modal .modal-dialog .modal-content .modal-body {
        padding: 30px;
        align-content: space-between;
        display: flex;
        flex-wrap: wrap; }
        @media screen and (min-width: 768px) {
          .modal .modal-dialog .modal-content .modal-body {
            min-height: 300px;
            padding-left: 65px;
            position: relative;
            align-content: space-between;
            display: flex;
            flex-wrap: wrap; }
            .modal .modal-dialog .modal-content .modal-body .modal-buttons {
              margin-bottom: 24px; } }
  .modal .form-group,
  .modal .form-control {
    width: 100%;
    max-width: none !important; }
  .modal .form-group {
    margin-bottom: 24px; }
  .modal .form-control {
    border: 1px #979797 solid;
    border-radius: 4px; }

.modal-backdrop.show {
  opacity: 0.8; }

#loanAmount .initiation-fee {
  margin-left: -30px; }
  @media screen and (max-width: 767px) {
    #loanAmount .initiation-fee {
      margin-left: 0px; } }

.tooltip {
  font-weight: 300; }
  .tooltip .tooltip-inner {
    max-width: 300px !important;
    border-radius: 16px;
    background-color: #393939;
    padding: 20px 20px 32px 20px;
    text-align: left;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3); }

.tooltip[x-placement^="top"] .arrow::before {
  border-color: none;
  border-top-color: #393939; }

.tooltip[x-placement^="bottom"] .arrow::before {
  border-color: none;
  border-bottom-color: #393939; }

.tooltip[x-placement^="right"] .arrow::before {
  border-color: none;
  border-right-color: #393939; }

.tooltip[x-placement^="left"] .arrow::before {
  border-color: none;
  border-left-color: #393939; }

.tooltip.show {
  opacity: 1 !important; }

.tooltip.landing-tooltip {
  width: 368px; }
  @media (max-width: 767px) {
    .tooltip.landing-tooltip {
      width: calc(100% - 8px);
      padding: 0px 8px; } }
  .tooltip.landing-tooltip .tooltip-inner {
    max-width: none !important;
    width: 100%; }
  .tooltip.landing-tooltip .label-money-item {
    border-bottom: 1px solid #5D5D5D;
    padding: 8px 0px; }
  .tooltip.landing-tooltip small {
    opacity: 1;
    font-weight: 300; }

#truid {
  background-color: #F3F6F4;
  width: 100%;
  height: 100%; }
  #truid .iframe {
    background-color: #C7C7C7;
    border: none;
    height: 800px; }
    #truid .iframe.iframe-ready {
      background-color: #F3F6F4; }

.modal-dialog .modal-content .content {
  width: 100%; }

.modal-dialog .modal-popup-close-btn {
  position: absolute;
  right: 18px;
  top: 20px;
  z-index: 1080;
  cursor: pointer !important;
  opacity: 0.5; }
  .modal-dialog .modal-popup-close-btn:hover {
    opacity: 1; }

.modal-dialog .btn {
  margin-right: inherit !important; }

.modal-popup .modal-header {
  position: relative;
  background: #231f20;
  color: white;
  text-align: center;
  padding-left: 25px !important;
  padding-right: 25px !important;
  border-radius: 3px 3px 0 0; }
  .modal-popup .modal-header h5 {
    width: 100%;
    font-size: 14px; }
    .modal-popup .modal-header h5 img {
      margin-bottom: 7px; }
    .modal-popup .modal-header h5 span {
      display: block;
      font-weight: 300; }
  @media screen and (min-width: 768px) {
    .modal-popup .modal-header {
      padding-right: 50px !important;
      padding-left: 50px !important; }
      .modal-popup .modal-header h5 {
        text-align: left;
        display: flex;
        align-items: center;
        font-size: 20px; }
        .modal-popup .modal-header h5 img.icon {
          margin-right: 26px;
          margin-left: 0 !important;
          margin-bottom: 0; }
        .modal-popup .modal-header h5 span {
          display: inline; } }

.modal-popup .modal-popup-body {
  padding: 16px !important;
  align-content: space-between;
  display: flex;
  flex-wrap: wrap; }

@media screen and (min-width: 768px) {
  .modal .modal-dialog .modal-content .modal-body.modal-popup-body {
    min-height: auto !important;
    padding: 24px 48px !important;
    position: relative;
    align-content: space-between;
    display: flex;
    flex-wrap: wrap; }
    .modal .modal-dialog .modal-content .modal-body.modal-popup-body .modal-buttons {
      position: relative !important;
      flex-direction: row !important; }
      .modal .modal-dialog .modal-content .modal-body.modal-popup-body .modal-buttons.justify-content-center {
        width: 100% !important; }
      .modal .modal-dialog .modal-content .modal-body.modal-popup-body .modal-buttons.more-actions {
        width: 100% !important; } }

.chartjs {
  height: 100%;
  width: 100%; }
  .chartjs > div {
    height: 100%; }

#user-info .btn-wrapper {
  width: 28%; }

@media (max-width: 768px) {
  #user-info .company-date-time {
    width: 45%; } }

#user-info select {
  color: initial !important; }

#introtruid .truid-intro-item {
  gap: 80px; }
  @media screen and (max-width: 767px) {
    #introtruid .truid-intro-item {
      gap: 16px; } }

#introtruid .item-icon {
  align-items: center; }

#introtruid .title-icon-small {
  font-size: 14px;
  font-weight: 300; }

#introtruid b {
  font-weight: bold; }

#introtruid hr {
  width: 100%; }

.intro-sprint-hive a {
  color: #0D73EE; }
  .intro-sprint-hive a:hover {
    text-decoration: underline !important; }

.intro-truid-who {
  background-color: #F4F4F4;
  min-height: 192px; }
  @media screen and (max-width: 767px) {
    .intro-truid-who {
      text-align: center; }
      .intro-truid-who h3 {
        font-size: 20px; }
      .intro-truid-who .intro-truid-who__content {
        font-size: 14px; } }
  .intro-truid-who .intro-truid-who__title {
    font-size: 24px;
    font-weight: 500; }

#debicheck {
  background-color: #F4F4F4; }
  #debicheck b {
    font-weight: bold; }
  #debicheck .debi-confirm {
    padding: 0px; }
    #debicheck .debi-confirm .action-step-list {
      gap: 16px;
      padding: 0px; }
    #debicheck .debi-confirm .step-item-icon {
      width: 64px;
      height: 64px; }
      @media (max-width: 767px) {
        #debicheck .debi-confirm .step-item-icon {
          width: 60px;
          height: 60px; } }
    #debicheck .debi-confirm .step-item-description {
      width: 100% !important; }
  #debicheck .section {
    background-color: #ffffff; }
    #debicheck .section > div {
      display: flex;
      flex-direction: column;
      padding-top: 48px;
      padding-bottom: 48px;
      gap: 48px; }
      @media (max-width: 767px) {
        #debicheck .section > div {
          gap: 24px; } }
    #debicheck .section .debicheck-steps {
      padding: 0px !important; }

.modal-active-gprofile-content {
  width: 455px; }

@media screen and (max-width: 768px) {
  .modal-active-gprofile-content {
    width: 100%; } }

.x-landing-header {
  position: relative;
  display: flex;
  min-height: 864px;
  z-index: 1; }
  .x-landing-header .tip-content {
    height: 32px; }
  .x-landing-header .within {
    color: #6E3474; }
  .x-landing-header .btn-common {
    margin: 0 !important; }
  .x-landing-header .logo-information {
    width: 410px;
    height: 280px;
    position: absolute;
    background-image: url(../assets/images/x2-icon/landing-logo.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0; }
  .x-landing-header .point-one {
    position: absolute;
    border-radius: 100%;
    width: 370px;
    height: 370px;
    background-color: #6E3474;
    z-index: -1; }
  .x-landing-header .point-two {
    position: absolute;
    border-radius: 100%;
    width: 276px;
    height: 276px;
    background-color: #6E3474;
    z-index: -1; }
  .x-landing-header .header-left {
    position: relative;
    padding: 0;
    z-index: 10;
    margin-top: 120px; }
    .x-landing-header .header-left .logo-information {
      display: none; }
    .x-landing-header .header-left .point-one {
      display: none; }
    .x-landing-header .header-left .point-two {
      display: none; }
  .x-landing-header .header-right {
    padding: 0;
    margin-top: 200px;
    margin-bottom: -72px; }
    .x-landing-header .header-right .point-one {
      top: -20%;
      left: 80%;
      transform: translate(-60%, 0%) !important; }
    .x-landing-header .header-right .point-two {
      top: 120px;
      left: 20%;
      transform: translate(-75%, 0%); }
    .x-landing-header .header-right .header-calculator {
      position: relative;
      width: 100%;
      height: auto !important;
      border-radius: 16px;
      background-color: #1A1A1A;
      color: #ffffff;
      padding: 48px;
      z-index: 10; }
    .x-landing-header .header-right .header-list-month {
      min-height: 100px; }
    .x-landing-header .header-right .x-monthy-payments {
      font-size: 20px !important; }
    .x-landing-header .header-right .logo-information {
      top: -25%;
      left: 50%;
      transform: translate(-60%, 0%) !important; }
  @media (max-width: 767px) {
    .x-landing-header {
      padding: 32px 16px;
      height: auto;
      margin-top: 0px !important;
      justify-content: flex-start; }
      .x-landing-header .header-left {
        width: 100%;
        max-width: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 0; }
        .x-landing-header .header-left .header-left-title {
          min-height: 220px; }
        .x-landing-header .header-left .logo-information {
          display: block;
          z-index: -1;
          width: 256px;
          height: 176px;
          bottom: -4px;
          left: 100%;
          transform: translate(-52%, 0%) !important; }
        .x-landing-header .header-left .solid-line {
          margin-top: -4px;
          margin-right: -16px; }
        .x-landing-header .header-left .point-one {
          display: block;
          width: 216px;
          height: 216px;
          bottom: 1%;
          left: 100%;
          transform: translate(-50%, 0%) !important; }
        .x-landing-header .header-left .point-two {
          display: block;
          width: 136px;
          height: 136px;
          bottom: 20%;
          right: 100%;
          transform: translate(4%, 70%) !important; }
      .x-landing-header .header-right {
        position: relative;
        width: 100%;
        max-width: none;
        margin-top: 0px; }
        .x-landing-header .header-right .logo-information {
          display: none; }
        .x-landing-header .header-right .point-one {
          display: none; }
        .x-landing-header .header-right .point-two {
          display: none; }
      .x-landing-header .header-calculator {
        padding: 32px 16px !important; } }
  @media only screen and (max-width: 767px) and (min-width: 464px) and (max-width: 767px) {
    .x-landing-header .header-left .logo-information {
      left: 96%;
      transform: translate(-80%, 0%) !important; }
    .x-landing-header .header-left .point-one {
      width: 240px;
      height: 240px;
      left: 100%;
      transform: translate(-80%, 0%) !important; } }
  @media only screen and (max-width: 767px) and (min-width: 672px) {
    .x-landing-header .header-left .logo-information {
      left: 90%;
      transform: translate(-100%, 0%) !important; } }
  .x-landing-header .item-value {
    min-width: 120px; }

.x-landing-step {
  width: 100% !important;
  min-height: 600px;
  background-color: #FDC600; }
  .x-landing-step .container {
    min-height: 400px; }
  .x-landing-step .x-landing-step-icon {
    justify-content: center; }
  @media (max-width: 767px) {
    .x-landing-step {
      padding: 48px 0px; }
      .x-landing-step .x-landing-step-icon {
        justify-content: start; } }
  .x-landing-step .list-step {
    justify-content: space-evenly; }
  .x-landing-step .step-item {
    background-color: #fff;
    width: 22%;
    padding: 32px 24px;
    border-radius: 16px; }
    @media (max-width: 767px) {
      .x-landing-step .step-item {
        padding: 24px;
        width: 100%; } }
    .x-landing-step .step-item h5 {
      min-height: 40px; }

.x-step-point {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #FFCC00; }

.x-step-point-index {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -16px;
  top: -16px;
  width: 44px !important;
  height: 44px !important;
  border: 4px solid #FFFFFF;
  border-radius: 100%;
  color: #FFFFFF;
  background-color: #000000; }
  @media (max-width: 767px) {
    .x-step-point-index {
      left: initial;
      right: -16px; } }

.x-landing-ready {
  background-image: url(../assets/images/x2-icon/landing-ready.svg);
  width: 100% !important;
  height: 560px !important;
  color: #ffffff;
  background-size: cover; }
  .x-landing-ready .hight-light {
    color: #FFCC00; }
  @media (max-width: 767px) {
    .x-landing-ready {
      background-image: url(../assets/images/x2-icon/landing-ready-mobile.svg);
      padding: 48px 16px;
      height: 409px !important;
      background-size: cover; } }

.x-landing-question {
  color: #1A1A1A;
  padding: 32px 16px;
  margin-bottom: 16px;
  font-weight: 500;
  min-height: 600px !important; }
  @media (max-width: 767px) {
    .x-landing-question .answer-wrap {
      width: 100% !important;
      min-height: 64px !important; } }
  .x-landing-question .answer-wrap {
    position: relative;
    width: 56%;
    max-width: 600px; }
    .x-landing-question .answer-wrap .background {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 16px; }
    .x-landing-question .answer-wrap .arrow-question {
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      width: 32px;
      height: 24px; }
    .x-landing-question .answer-wrap ul li {
      margin-left: 16px;
      list-style: disc; }
      .x-landing-question .answer-wrap ul li.alpha {
        list-style: lower-alpha; }
    .x-landing-question .answer-wrap ul ::marker {
      font-weight: 500; }
  .x-landing-question .answer-item {
    width: 100%;
    position: relative;
    padding: 28px 0px;
    justify-content: space-between;
    align-items: start; }
  .x-landing-question .message-right .background {
    box-shadow: 0px 0px 10px 0px #0000001A;
    background-color: #6E3474;
    color: #ffffff; }
  .x-landing-question .message-right .arrow-question {
    background-image: url(../assets/images/x2-icon/message-arrow-right.svg);
    left: 2px;
    top: 24%;
    transform: translate(-50%, 20%); }
  .x-landing-question .message-right .pl-accordion-content {
    color: rgba(255, 255, 255, 0.65); }
  .x-landing-question .message-left .background {
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); }
  .x-landing-question .message-left .background:after {
    border: solid transparent;
    content: " ";
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 18px;
    z-index: 1;
    right: -29px;
    top: 25%;
    width: 32px;
    height: 24px;
    transform: rotate(-90deg); }
  .x-landing-question .message-left .background:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 36px;
    z-index: -5;
    right: -5px;
    top: 25%;
    transform: rotate(45deg);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); }
  .x-landing-question .message-left .pl-accordion-content {
    color: rgba(0, 0, 0, 0.65); }
  .x-landing-question svg {
    transform: rotate(90deg);
    margin-top: -8px; }
  .x-landing-question .pl-accordion-content {
    padding-top: 0;
    width: 100%;
    margin-top: -20px;
    padding-bottom: 24px;
    white-space: pre-wrap; }
  .x-landing-question .pl-accordion-arrow {
    transform: rotate(90deg); }
  .x-landing-question .arrow-active {
    transform: rotate(270deg); }

.x-landing-faq.modal-dialog {
  width: 100%;
  max-width: 1024px; }
  .x-landing-faq.modal-dialog b {
    font-weight: 500; }
  .x-landing-faq.modal-dialog .pl-accordion {
    padding: 16px 24px !important; }
  @media (max-width: 767px) {
    .x-landing-faq.modal-dialog {
      padding: 16px !important; } }
  .x-landing-faq.modal-dialog .pl-accordion-content {
    padding-top: 0px !important;
    font-size: 14px; }
  .x-landing-faq.modal-dialog .title {
    font-size: 16px !important; }
  .x-landing-faq.modal-dialog .label-title {
    font-size: 20px !important; }
  .x-landing-faq.modal-dialog .pl-accordion-title {
    flex-direction: row !important;
    align-items: flex-start; }

#DOM_FT_overlayContainer {
  background: rgba(0, 0, 0, 0.75) !important; }
  @media (max-width: 767px) {
    #DOM_FT_overlayContainer {
      background-color: #fff !important; } }

.CLASS_FT_cameraLoadingDefaultStyle {
  position: absolute !important;
  border: none !important;
  display: block !important;
  margin: 0 !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 72px !important;
  width: 72px !important;
  background-image: url(../assets/images/move.png);
  border-radius: 0 !important;
  background-size: 3744px auto;
  animation: animationSpin 1s steps(52) infinite !important;
  -webkit-animation: animationSpin 1s steps(52) infinite !important; }
  @media (min-width: 767px) {
    .CLASS_FT_cameraLoadingDefaultStyle {
      width: 128px !important;
      height: 128px !important;
      background-size: 6656px auto !important;
      animation: animationSpinMd 1s steps(52) infinite !important;
      -webkit-animation: animationSpinMd 1s steps(52) infinite !important;
      margin: 0 !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important; } }

#DOM_FT_PRIMARY_TOPLEVEL_mainContainer path {
  stroke: #ffcc00 !important;
  stroke-width: 3 !important; }

#DOM_FT_PRIMARY_TOPLEVEL_mainContainer path#DOM_FT_ovalPathCombined {
  stroke: none !important; }

#DOM_FT_PRIMARY_TOPLEVEL_mainContainer * {
  color: #231f20 !important; }

#DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_frameContainer button {
  background-color: #FDC600 !important;
  color: #231f20 !important;
  box-shadow: none !important;
  font-size: 16px !important;
  cursor: pointer !important;
  border-radius: 24px; }
  #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_frameContainer button:hover {
    color: #ffffff !important;
    box-shadow: 0px 2px 12px 4px rgba(255, 204, 0, 0.5); }
  #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_frameContainer button.disabled, #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_frameContainer button:disabled {
    background-color: rgba(102, 102, 102, 0.1) !important;
    color: #231f20 !important;
    border-color: transparent !important; }
    #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_frameContainer button.disabled:hover, #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_frameContainer button:disabled:hover {
      box-shadow: none !important; }

#DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_frameContainer #DOM_FT_cancelButtonElement {
  background-color: transparent !important;
  width: 16px !important;
  height: 16px !important;
  cursor: pointer !important;
  background-color: transparent !important;
  border: none !important;
  right: 2px !important;
  left: initial !important; }
  #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_frameContainer #DOM_FT_cancelButtonElement:hover {
    box-shadow: none !important; }

#DOM_FT_PRIMARY_TOPLEVEL_mainContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: transparent !important; }
  #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_feedbackBarContainer {
    background-color: transparent !important;
    box-shadow: none !important; }
  #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_feedbackBarElement {
    background-color: #FDC600 !important;
    color: #231f20 !important;
    box-shadow: none !important;
    font-size: 16px !important;
    width: 100%;
    height: 48px;
    position: absolute;
    bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px !important; }
  @media (max-width: 767px) {
    #DOM_FT_PRIMARY_TOPLEVEL_mainContainer button {
      height: 32px !important; }
    #DOM_FT_PRIMARY_TOPLEVEL_mainContainer button,
    #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_feedbackBarContainer {
      max-width: 320px !important; }
    #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_feedbackBarContainer {
      margin-top: 20px !important; } }
  @media (min-width: 767px) {
    #DOM_FT_PRIMARY_TOPLEVEL_mainContainer button,
    #DOM_FT_PRIMARY_TOPLEVEL_mainContainer #DOM_FT_feedbackBarElement {
      height: 40px !important; } }

#DOM_FT_frameContainer {
  position: absolute !important;
  z-index: 5;
  top: 0px !important;
  left: 0px !important; }
  @media (min-width: 767px) {
    #DOM_FT_frameContainer {
      position: relative !important;
      width: 100% !important;
      height: 440px !important; } }

#DOM_FT_getReadyScreen {
  text-align: center !important; }
  #DOM_FT_getReadyScreen #DOM_FT_readyScreenHeaderElement {
    font-size: 24px !important; }
  #DOM_FT_getReadyScreen #DOM_FT_readyScreenSubtextElement {
    font-size: 14px !important;
    line-height: 1.2;
    padding-top: 8px !important; }
  @media (min-width: 767px) {
    #DOM_FT_getReadyScreen {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      padding-top: 24px !important; }
      #DOM_FT_getReadyScreen #DOM_FT_readyScreenSubtextElement {
        font-size: 16px !important; } }
  #DOM_FT_getReadyScreen #DOM_FT_readyScreenInnerContainer {
    padding-bottom: 64px !important; }
  @media (max-width: 767px) {
    #DOM_FT_getReadyScreen #DOM_FT_readyScreenInnerContainer > div {
      width: 100%;
      gap: 4px; } }

#DOM_FT_mainInterfaceNonOverlayContainer {
  margin: 0;
  margin-top: 80px !important;
  height: 440px !important;
  border: none !important;
  border-radius: 4px !important;
  box-shadow: 4px 4px 16px 4px #C7C7C7 !important; }
  @media (max-width: 767px) {
    #DOM_FT_mainInterfaceNonOverlayContainer {
      margin-top: 16px !important;
      box-shadow: none !important;
      height: inherit !important; } }

/**
SVG face
*/
@media (min-width: 767px) {
  video#DOM_FT_videoElement {
    position: absolute !important;
    top: 80px !important;
    height: 440px !important;
    width: 100% !important;
    z-index: 3;
    left: 0px !important;
    right: 0px !important; } }

#DOM_FT_frameOutsideOvalMask {
  top: 72px !important;
  width: 100% !important; }
  @media (max-width: 767px) {
    #DOM_FT_frameOutsideOvalMask {
      top: inherit !important;
      width: inherit !important; } }

#DOM_FT_ovalSVG,
#DOM_FT_ovalSVG_2 {
  top: 80px !important; }
  @media (max-width: 767px) {
    #DOM_FT_ovalSVG,
    #DOM_FT_ovalSVG_2 {
      top: 0px !important; } }

#DOM_FT_frameOutsideOvalSVG {
  top: 40px !important; }
  @media (max-width: 767px) {
    #DOM_FT_frameOutsideOvalSVG {
      top: inherit !important; } }

@media (min-width: 767px) {
  #DOM_FT_faceScanCameraTransitionView {
    display: block !important;
    z-index: 2; } }

#DOM_FT_retryScreenHeaderElement {
  font-size: 24px !important;
  padding-bottom: 8px !important; }

#DOM_FT_retryScreenSubtextElement {
  font-size: 16px !important; }

#DOM_FT_retryImagesContainer * {
  font-size: 16px !important; }

#DOM_FT_rightRetryImageBorder,
#DOM_FT_leftRetryImageBorder {
  border: 0 !important;
  border-radius: 12px !important; }

#DOM_FT_retryScreenInnerContainer {
  padding-bottom: 64px !important; }
  #DOM_FT_retryScreenInnerContainer #DOM_FT_retryScreenSubtextElement {
    display: none !important; }

#DOM_FT_leftRetryImageSubtextContainer,
#DOM_FT_rightRetryImageSubtextContainer {
  margin-top: 8px !important; }

#DOM_FT_voiceAssistAlertElement * {
  color: #FDC600 !important; }

#DOM_FT_getReadyAndRetryScreenContainer {
  display: flex !important;
  justify-content: center !important; }
  #DOM_FT_getReadyAndRetryScreenContainer *:focus,
  #DOM_FT_getReadyAndRetryScreenContainer *:focus-visible {
    outline: none !important;
    outline-width: 0 !important; }

#DOM_FT_readyScreenHeaderContainer,
#DOM_FT_readyScreenSubtextContainer {
  max-width: none !important;
  border: none !important;
  box-shadow: none !important;
  padding-top: 8px !important; }
  @media (min-width: 767px) {
    #DOM_FT_readyScreenHeaderContainer,
    #DOM_FT_readyScreenSubtextContainer {
      max-width: 400px !important;
      padding-top: revert-layer !important; } }

#DOM_FT_readyScreenSubtextContainer p {
  font-size: 14px !important; }
  @media (min-width: 767px) {
    #DOM_FT_readyScreenSubtextContainer p {
      font-size: inherit !important; } }

#DOM_FT_readyScreenSubtextContainer {
  height: auto !important; }

#DOM_FT_retryScreen #DOM_FT_retryInstructionsListContainer {
  text-align: center !important; }

#DOM_FT_retryScreen #DOM_FT_retryInstructionParagraphElement1,
#DOM_FT_retryScreen #DOM_FT_retryInstructionParagraphElement2 {
  font-size: 16px !important; }
  @media (max-width: 767px) {
    #DOM_FT_retryScreen #DOM_FT_retryInstructionParagraphElement1,
    #DOM_FT_retryScreen #DOM_FT_retryInstructionParagraphElement2 {
      max-width: 400px;
      font-size: 14px !important;
      white-space: pre-wrap !important;
      word-wrap: break-word !important; } }

/**
Upload screen
*/
#DOM_FT_uploadAnimationBounce1,
#DOM_FT_uploadAnimationBounce2,
#DOM_FT_uploadProgressBarFill {
  background-color: #FDC600 !important; }

#DOM_FT_uploadProgressScreen {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-align: center; }

#DOM_FT_uploadProgressContainer {
  position: relative !important;
  top: 0 !important; }
  #DOM_FT_uploadProgressContainer #DOM_FT_uploadProgressMessageContainer {
    position: relative !important;
    top: 0 !important; }
  #DOM_FT_uploadProgressContainer #DOM_FT_uploadProgressMessageElement {
    font-size: 16px !important;
    line-height: 1.2 !important; }
    @media (min-width: 767px) {
      #DOM_FT_uploadProgressContainer #DOM_FT_uploadProgressMessageElement {
        font-size: 36px !important; } }
  #DOM_FT_uploadProgressContainer #DOM_FT_uploadProgressBarTrack {
    position: relative !important;
    top: 0;
    background-color: #E3E3E3;
    width: 224px !important; }

.CLASS_FT_resultScreenRelativeContentTopSpacer {
  display: none;
  opacity: 0; }

#DOM_FT_logoAndVersionOuterContainer {
  display: none;
  opacity: 0;
  position: absolute;
  z-index: -1; }

#DOM_FT_yourAppLogoContainer {
  display: none;
  opacity: 0;
  position: absolute !important;
  height: 0px !important; }

.modal-selfie-retry {
  width: 100% !important;
  margin-top: inherit !important; }
  @media screen and (min-width: 768px) {
    .modal-selfie-retry {
      margin-top: 80px !important;
      padding: inherit !important; } }
  @media screen and (max-width: 768px) {
    .modal-selfie-retry {
      padding: 0px !important;
      height: 100% !important;
      background-color: white; } }
  .modal-selfie-retry .modal-body.modal-popup-body {
    padding-bottom: 24px !important; }
    @media screen and (min-width: 768px) {
      .modal-selfie-retry .modal-body.modal-popup-body {
        padding-bottom: 0px !important; } }
  .modal-selfie-retry #DOM_FT_frameContainer {
    position: relative !important;
    height: auto !important;
    width: 100% !important;
    background-color: white !important; }
  .modal-selfie-retry .modal-buttons {
    margin-bottom: 0px !important; }
  .modal-selfie-retry button {
    width: 250px;
    height: 40px !important;
    line-height: initial !important;
    margin-bottom: 0px !important;
    border-radius: 8px !important; }
  .modal-selfie-retry .CLASS_FT_retryImageContainer {
    background-color: gray; }
  .modal-selfie-retry #DOM_FT_retryImagesContainer img {
    object-fit: cover !important;
    margin: 0;
    padding: 0;
    border-radius: 10px !important; }
  .modal-selfie-retry .CLASS_FT_retryLeftContainer img {
    transform: scaleX(-1); }
  @media screen and (min-width: 768px) {
    .modal-selfie-retry {
      width: 640px !important; } }

.button-permission {
  color: #0F7ABD;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none; }
  .button-permission:hover {
    opacity: 0.65; }

.modal-permission-camera {
  width: auto !important;
  max-width: 560px !important; }
  .modal-permission-camera .step-point {
    background-color: transparent;
    height: 72px;
    margin: 8px !important; }
  .modal-permission-camera .point {
    min-width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #FFCC00;
    color: #231F20; }
  .modal-permission-camera .point-item {
    margin-top: -4px; }
  .modal-permission-camera .wrapper-modal-title {
    font-size: 20px !important; }

.intro-selfie-steps .step-item-icon {
  background-color: transparent !important; }

.intro-selfie-steps .step-item-info {
  gap: 60px; }

.intro-selfie-steps .face-mobile-lighting {
  margin-left: 40px; }

.selfie-steps .step-item-info {
  gap: 16px;
  align-items: center !important; }

.selfie-line {
  min-width: 280px;
  padding: 16px;
  border-top: 1px solid #E3E3E3; }
